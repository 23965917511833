
.select{
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: none;
    text-transform: capitalize;
    text-align: left;
    padding: 0 15px;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    border:1px solid #ffff;
    /* color: #ffff; */
    /* margin:10px */
  }
  
  .select::after{
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-right: 2px solid rgb(0, 0, 0);
    border-bottom: 2px solid rgb(0, 0, 0);
  }
  
  
  
  .select.active{
    background: #222222;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .options{
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: fit-content;
    background: rgb(32, 30, 30);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid rgba(173, 170, 170, 0.603);
    overflow: hidden;
    display: none;
    transition: display 2s linear 1s;
  }
  
  .options.active{
    display: block;
    transition: display 2s linear 1s;
  
    
  }
  
  .options .item{
    color: rgb(62, 60, 60);
    text-transform: capitalize;
    width: 100%;
    height: 30px;
    padding: 0 15px;
    line-height: 30px;
    cursor: pointer;
    font-size: medium;
    
  }
  
  .options .item:hover{
    background: #b5b7b780;
  }
  
  .options .itemo{
    color: rgb(62, 60, 60);
    text-transform: capitalize;
    width: 100%;
    height: 30px;
    padding: 0 15px;
    line-height: 30px;
    cursor: pointer;
    font-size: medium;
    display: flex;
    align-items: center;
    justify-content: space-evenly
    
  }
  
  .options .itemo:hover{
    background: #b5b7b780;
  }
  .colorRound{
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid gainsboro;
  }
  
  textarea {
    /* background: url(images/benice.png) center center no-repeat; */
    border: 1px solid #888; 
    border-radius: 10px;
    padding:30px;
  }