::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 2.5rem !important;
}

/* sx={{
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "70%",
}} */

.jodit .jodit-workplace,
.jodit-container .jodit-workplace {
  min-height: 30rem;
  background-color: #ffff;
}
